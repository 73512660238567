import MainLayout from '../components/MainLayout/MainLayout'
import Homepage from '@BidftaStrapi/Pages/Homepage'
import React from 'react'
import { SectionComponent } from '@BidftaStrapi/Components/types'
import { strapiInstance } from '@utilities/axios/strapi'

import homepageQuery from '@BidftaStrapi/utils/homepageQuery'
import organizePageData from '@BidftaStrapi/utils/organizePageData'
import deepMarkdownSerialization from '@BidftaStrapi/utils/deepMarkdownSerialization'
import { SEODataType } from '@BidftaStrapi/Components/SEOData/types'

export async function getStaticProps() {
  const { data } = await strapiInstance.get(homepageQuery)

  const seo = data?.data[0]?.attributes?.landing_page?.data?.attributes ?? null

  const organizedData = organizePageData(data)

  return {
    props: {
      homepage: await deepMarkdownSerialization(organizedData),
      seo,
    },
  }
}

export default function Home({
  homepage,
  seo,
}: {
  homepage: SectionComponent[][]
  seo: SEODataType
}) {
  return (
    <MainLayout
      title="Online Auctions powered by BidFTA"
      previewDescription="Browse through thousands of online auctions powered by BidFTA at a location near you. Find the best deals on products ranging from home goods to electronics."
      {...seo}
    >
      <div className="xs:rounded-xl hidden ease-in-out duration-200 text-[48px]" />
      <Homepage content={homepage} />
    </MainLayout>
  )
}
